/* *********************************************************************** */

/* High contras theme section  */

@media (-ms-high-contrast: active), (forced-colors: active) {
  /* Reset the checkbox styles to default */
  input[type='checkbox'],
  .check input {
    appearance: checkbox !important;
    -webkit-appearance: checkbox !important;
    -moz-appearance: checkbox !important;
    background-color: initial !important;
    background-image: none !important;
  }

  /* Reset the checked checkbox styles to default */
  input[type='checkbox']:checked,
  .check input:checked {
    background-color: initial !important;
    background-image: none !important;
  }
}

