// Fonts Size
$font-10: 10px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-30: 30px;
$font-32: 32px;
$font-33: 33px;
$font-34: 34px;
$font-36: 36px;
$font-45: 45px;
$font-57: 57px;
$font-60: 60px;

// Fonts Colors
$fontcolor: #495057;
$graybg: #434750;
$lightgray: #6f6f6f;
$white: #fff;
$black: #000;
$iconcolor: #cccccc;
$red: #ff1761;
$green: #1bc564;
$header-link: #2c2c2c;
$light-text: #9b9b9b;

//Background color
$bg_light: #eeeeee;
$splesh-bg: #13293d;
$footer-background: #f9f9f9;

// Links Buttons
$linkcolor: #177dff;
$hovercolor: #1e81fe;
$blue-button: #0067b8;

//Footer
$footerbg: #292929;
$copyrightbg: #252726;
